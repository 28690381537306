import { useGetAlyziaConfigQuery } from '@codegen/cmsUtils';
import { OfferResponse, usePatchOfferIdServices } from '@codegen/offerAPI';
import { ServiceClass } from '@shared/types/enums';
import { useConstants } from '@web/context/ConstantContext';
import useGetOffer from '@web/context/hooks/useGetOffer';
import { OfferMutations } from '@web/context/hooks/useIsUpdating';
import { useSettings } from '@web/context/SettingsContext';
import {
  getAlyziaGroundHandlingFromAllPaxServices,
  updateOfferWithUpdatedAlyziaGroundHandling,
} from '@web/utils/booking/alyziaGroundHandlingUtils';
import { isLuggageService } from '@web/utils/booking/serviceUtils';
import useServices from './useServices';
import { useSummary } from './useSummary';

const useAlyziaGroundHandling = () => {
  const { offer, setOffer } = useGetOffer();
  const { allAdditionalServices } = useSummary({ summary: offer?.summary });
  const { locale } = useConstants();
  const { currency } = useSettings();

  const alyziaGroundHandlingService = getAlyziaGroundHandlingFromAllPaxServices(
    offer?.all_pax_services,
  );

  const { passengersServices } = useServices({
    serviceClasses: [ServiceClass.LUGGAGE_CHECKED],
  });

  const includedLuggageService = passengersServices.filter(
    (services) =>
      services.includedServices && services.includedServices.length > 0,
  );

  const additionalLuggageServices = allAdditionalServices.filter(
    (service) => service.serviceClass && isLuggageService(service.serviceClass),
  );

  const { data: alyziaConfig, isLoading: isAlyziaConfigLoading } =
    useGetAlyziaConfigQuery({ locale });

  const { mutate } = usePatchOfferIdServices({
    mutation: {
      mutationKey: [OfferMutations.ALYZIA_SELECT],
      onMutate: ({ data }) => {
        if (!offer) {
          return { previousOffer: offer };
        }

        const nextOffer = updateOfferWithUpdatedAlyziaGroundHandling({
          offer,
          serviceId: data.service_id,
          quantity: data.quantity,
        });

        const previousOffer = offer;

        setOffer(nextOffer);

        return { previousOffer };
      },
      onError: (_error, _data, context?: { previousOffer?: OfferResponse }) => {
        if (context?.previousOffer) {
          setOffer(context.previousOffer);
        }
      },
      onSuccess: (data) => {
        setOffer(data);
      },
    },
  });

  const updateAlyziaGroundHandling = () => {
    if (!offer?.offer_id || !alyziaGroundHandlingService?.service_id) {
      return;
    }

    mutate({
      offerId: offer.offer_id,
      data: {
        service_id: alyziaGroundHandlingService.service_id,
        quantity: alyziaGroundHandlingService.quantity === 0 ? 1 : 0,
      },
      params: { curr: currency },
    });
  };

  return {
    name: alyziaConfig?.alyziaConfig?.name ?? 'Alyzia',
    shouldShowAlyziaGroundHandling:
      additionalLuggageServices.length > 0 || includedLuggageService.length > 0,
    alyziaGroundHandlingService,
    updateAlyziaGroundHandling,
    isLoading: isAlyziaConfigLoading || !alyziaConfig?.alyziaConfig,
    title: alyziaConfig?.alyziaConfig?.title.value || '',
    description: alyziaConfig?.alyziaConfig?.description.value || '',
  };
};

export default useAlyziaGroundHandling;
