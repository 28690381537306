import Badge from '@ui-v2/core/Badge/Badge';
import Box from '@ui-v2/core/Box/Box';
import useCmsTranslation from '@utils/hooks/useCmsTranslation';
import Tooltip from '../Tooltip/Tooltip';

interface Props {
  onClickBadge: () => void;
}

const NoCheckedBagsBadge = ({ onClickBadge }: Props) => {
  const { t } = useCmsTranslation();

  return (
    <Tooltip
      id="no-checked-bags-tag-tooltip"
      text={t(
        'airport_transfer_under_90_min',
        'Airport transfer duration is under 90 min',
      )}
    >
      <Box display={['none', 'block']}>
        <Badge
          as="button"
          icon="noLuggageIcon"
          onClick={onClickBadge}
          variant="warning"
        >
          {t('no_checked_bag_journey', 'No checked-bag journey')}
        </Badge>
      </Box>
      <Box display={['block', 'none']}>
        <Badge
          as="button"
          icon="noLuggageIcon"
          onClick={onClickBadge}
          variant="warning"
        />
      </Box>
    </Tooltip>
  );
};

export default NoCheckedBagsBadge;
