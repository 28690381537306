import { ParsedUrlQuery } from 'querystring';
import { GetStaticPropsContext } from 'next';
import { dehydrate } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Vendors, Vendor } from '@codegen/cmsTypes';
import {
  PassengerRulesFragment,
  IconConfigFragment,
  ImageWithConfigFragment,
  BookingStepFragment,
} from '@codegen/cmsUtils';
import {
  BundleGroup,
  Leg,
  OfferBundleServicesItem,
  Passenger,
  OfferResponse,
  ServiceGroup,
  ServiceGroupServicesItem,
  PaxType,
} from '@codegen/offerAPI';
import {
  CurrencyCode,
  CMSPassengerType,
  ServiceClass,
  Partner,
} from '@shared/types/enums';
import { findVendorByIata } from '@ui/utils/vendorUtils';
import { head, tail } from '@utils/arrayUtils';
import { parseDateString } from '@utils/dateUtils';
import { createAnArray, partition } from '@utils/helperUtils';
import { TranslateCmsString } from '@utils/hooks/useCmsTranslation';
import { parseQueryString } from '@utils/queryUtils';
import { BundleService } from '@utils/sharedServiceUtils';
import { Route } from '@web/types/enums';
import { PassengerServiceGroup } from '@web/types/passengerServiceTypes';
import { DEFAULT_REVALIDATE_TIME, defaultGetStaticProps } from '../nextUtils';
import { constructSearchResultQueryPushParams } from '../searchUtils';
import { constructCombinedServices } from './serviceUtils';
import { getIatasFromSummary } from './summaryUtils';

export type PassengerServiceMismatch = {
  [key: string]: BundleService;
};

export type ServiceMismatch = {
  [key: string]: PassengerServiceMismatch[];
};

export const getNumberOfVendorsFromDeeplink = (faresString?: string | null) => {
  if (!faresString) {
    return 1;
  }

  const fares = faresString.split('--');

  if (fares.length === 1) {
    return 1;
  }

  const vendors = fares.map((fare) => {
    const fareItems = fare.split('-');
    const vendor = fareItems[fareItems.length - 1];

    return vendor;
  });

  return new Set(vendors).size;
};

export const getBundleGroupFromIata = (
  bundleGroups: BundleGroup[],
  iata: string,
) => {
  return bundleGroups.find((bg) => bg.carrier_codes.includes(iata));
};

export const getSelectedBundleFromIata = (
  bundleGroups: BundleGroup[],
  iata: string,
) => {
  return getBundleGroupFromIata(bundleGroups, iata)?.bundles.find(
    (bundle) => bundle.is_selected,
  );
};

export const getFilteredServices = (
  serviceClasses: ServiceClass[],
  services?: OfferBundleServicesItem[],
) => {
  return (
    services?.filter((service) =>
      serviceClasses.includes(service.service_class as ServiceClass),
    ) || []
  );
};

export const getNoServiceGroupCarrier = ({
  carrierCode,
  getFallbackIcon,
  offer,
  passenger,
  serviceClasses,
  t,
  vendors,
}: {
  carrierCode: string;
  getFallbackIcon: (
    iconIdentifier: keyof IconConfigFragment,
  ) => ImageWithConfigFragment | null;
  offer: OfferResponse;
  passenger: Passenger;
  serviceClasses?: ServiceClass[];
  t: TranslateCmsString;
  vendors: Vendors;
}) => {
  const selectedBundle = getSelectedBundleFromIata(
    offer.bundle_groups,
    carrierCode,
  );

  const bundleGroup = getBundleGroupFromIata(offer.bundle_groups, carrierCode);

  const bundleServices =
    serviceClasses && serviceClasses.length > 0
      ? getFilteredServices(serviceClasses, selectedBundle?.services)
      : selectedBundle?.services ?? [];

  const cmsServices =
    findVendorByIata(vendors, carrierCode)?.vendorBookingConfig?.servicesConfig
      ?.services ?? [];

  const includedServices = constructCombinedServices({
    t,
    offerServices: bundleServices,
    cmsServices,
    getFallbackIcon,
  });

  return {
    iata: carrierCode,
    iatas: [carrierCode],
    carrier_codes: [carrierCode],
    includedServices,
    legs: bundleGroup?.legs ?? [],
    passenger,
    services: [],
  };
};

/**
 * Sorts passenger service groups in ascending order
 * by the dates of their legs.
 * Used to display baggage selections per route in the correct order.
 */
export const sortPassengerServiceGroupsByLegDates = (
  serviceGroups: Omit<PassengerServiceGroup, 'iatas'>[],
) => {
  return [...serviceGroups].sort((a, b) => {
    const [firstLegA] = a.legs;
    const [firstLegB] = b.legs;

    if (!firstLegA || !firstLegB) {
      return 0;
    }

    const lowestDateA = new Date(firstLegA.departure);
    const lowestDateB = new Date(firstLegB.departure);

    if (lowestDateA < lowestDateB) {
      return -1;
    } else if (lowestDateA > lowestDateB) {
      return 1;
    } else {
      return 0;
    }
  });
};

export const constructPassengersServices = ({
  carrierCodes,
  getFallbackIcon,
  offer,
  passengers,
  serviceClasses,
  t,
  vendors,
}: {
  carrierCodes: string[];
  getFallbackIcon: (
    iconIdentifier: keyof IconConfigFragment,
  ) => ImageWithConfigFragment | null;
  offer: Maybe<OfferResponse>;
  passengers: Passenger[];
  serviceClasses?: ServiceClass[];
  t: TranslateCmsString;
  vendors?: Vendors;
}) => {
  if (!offer || !vendors) {
    return [];
  }

  const carrierCodesNotInServiceGroup = carrierCodes.filter(
    (carrierCode) =>
      !offer.service_groups.find(
        (passengerService) =>
          passengerService.carrier_codes.includes(carrierCode) &&
          passengerService.services.find((service) =>
            serviceClasses?.includes(service.service_class as ServiceClass),
          ),
      ),
  );

  return passengers
    .filter((passenger) => passenger.expected_type !== PaxType.i)
    .reduce<PassengerServiceGroup[]>((accPaxService, serviceGroup) => {
      const paxId = serviceGroup.passenger_id;
      const paxInformation = offer.passengers.find(
        (passenger) => passenger.passenger_id === paxId,
      );

      if (
        !paxInformation ||
        accPaxService.some((pax) => pax.passenger.passenger_id === paxId)
      ) {
        return accPaxService;
      }

      const serviceGroupsForPax = offer.service_groups
        .filter(
          (sg) =>
            sg.passenger_id === paxId &&
            sg.services.some((s) =>
              serviceClasses?.includes(s.service_class as ServiceClass),
            ),
        )
        .map(({ passenger_id: _, ...sg }) => {
          const iata = sg.carrier_codes[0] as string;
          const selectedBundle = getSelectedBundleFromIata(
            offer.bundle_groups,
            iata,
          );

          const bundleServices =
            serviceClasses && serviceClasses.length > 0
              ? getFilteredServices(serviceClasses, selectedBundle?.services)
              : selectedBundle?.services ?? [];

          const sgServices =
            serviceClasses && serviceClasses.length > 0
              ? getFilteredServices(serviceClasses, sg.services)
              : sg.services;

          const cmsServices =
            findVendorByIata(vendors, iata)?.vendorBookingConfig?.servicesConfig
              ?.services ?? [];

          const includedServices = constructCombinedServices({
            t,
            offerServices: bundleServices,
            cmsServices,
            getFallbackIcon,
          });

          const services = constructCombinedServices({
            t,
            offerServices: sgServices,
            cmsServices,
            getFallbackIcon,
          });

          return {
            ...sg,
            iata,
            includedServices,
            services,
            passenger: paxInformation,
          };
        });

      const carriersInNoServiceGroupsForPax = carrierCodesNotInServiceGroup.map(
        (carrierCode) =>
          getNoServiceGroupCarrier({
            t,
            carrierCode,
            offer,
            vendors,
            serviceClasses,
            getFallbackIcon,
            passenger: paxInformation,
          }),
      );

      const combinedAndSortedServices = sortPassengerServiceGroupsByLegDates([
        ...serviceGroupsForPax,
        ...carriersInNoServiceGroupsForPax,
      ]);

      return [...accPaxService, ...combinedAndSortedServices];
    }, []);
};

const TRAIN_LEG_SPLIT = '__';
const LEG_SPLIT = '---';

// Borrowed from libdohop https://github.com/dohop/libdohop/blob/master/libdohop/pack_unpack.py#L130
const IATA =
  '[0-9][A-Z]|[A-Z]{2}|[A-Z][0-9]|[0-9][A-Z]{2}|[A-Z]{3}|[0-9]{2}[A-Z]';

const VENDOR_IATA_MATCH = new RegExp(`^(${IATA})([1-9][0-9]{0,3})$`);

const parseBookDeeplinkLegDateString = (legString: string) => {
  const dateString = legString.substring(6, 14);

  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);

  return parseDateString(`${year}-${month}-${day}`);
};

export const getIataCodeFromLegString = (legString: string) => {
  try {
    return legString.substring(27).match(VENDOR_IATA_MATCH)?.[1];
  } catch {
    return null;
  }
};

export const parseBookingDeeplink = (
  query: ParsedUrlQuery,
  vendorPassengerRules: PassengerRulesFragment | null,
) => {
  const {
    curr,
    currency,
    home,
    n_adults: adult,
    out,
    res,
    residency,
    utm_campaign: utmCampaign,
    utm_medium: utmMedium,
    utm_source: utmSource,
    youngsters_ages: child,
  } = query;

  try {
    const isOneWay = !home;

    if (!out || !adult || typeof out !== 'string') {
      return null;
    }

    const origin = out.substring(0, 3);
    const outboundLegs = out
      .split(LEG_SPLIT)
      .flatMap((leg) => leg.split(TRAIN_LEG_SPLIT));

    const homeboundLegs =
      typeof home === 'string'
        ? home.split(LEG_SPLIT).flatMap((leg) => leg.split(TRAIN_LEG_SPLIT))
        : [];

    const itineraryIatas = [
      ...new Set(
        [...outboundLegs, ...homeboundLegs]
          .map(getIataCodeFromLegString)
          .filter((iata): iata is string => Boolean(iata)),
      ),
    ];

    // We are using the length of the array to index here, so this should be safe
    const destination = outboundLegs[outboundLegs.length - 1]?.substring(
      3,
      6,
    ) as string;

    const departureDate = parseBookDeeplinkLegDateString(out);

    const returnDate = home
      ? parseBookDeeplinkLegDateString(home as string)
      : null;

    const adultMinAge = vendorPassengerRules?.rules.find(
      (paxRule) => paxRule.passengerType === CMSPassengerType.ADULT,
    )?.minAge;

    const childPaxRule = vendorPassengerRules?.rules.find(
      (paxRule) => paxRule.passengerType === CMSPassengerType.CHILD,
    );

    const childrenAges = child
      ? (child as string).split('-').map((age) => Number(age))
      : [];

    const [children, infant] = partition(
      (age: number) =>
        childPaxRule?.minAge && childPaxRule.maxAge
          ? age > childPaxRule.minAge && age <= childPaxRule.maxAge
          : false,
      childrenAges,
    );

    return {
      origin,
      destination,
      residency: residency || res,
      currency: currency || curr,
      utmSource,
      utmMedium,
      utmCampaign,
      isOneWay,
      departureDate,
      returnDate,
      paxTypeAges: {
        adult: createAnArray(Number(adult as string), adultMinAge || 18),
        child: child && childPaxRule ? children : childrenAges,
        infant: child && childPaxRule ? infant : [],
      } as Record<string, number[]>,
      itineraryIatas,
    };
  } catch (_) {
    return null;
  }
};

export const createSearchUrlFromBookingDeeplink = (
  query: ParsedUrlQuery,
  vendorPassengerRules: PassengerRulesFragment | null,
  steps?: BookingStepFragment[],
) => {
  const params = parseBookingDeeplink(query, vendorPassengerRules);

  if (!params) {
    return null;
  }

  const {
    currency,
    departureDate,
    destination,
    isOneWay,
    origin,
    paxTypeAges,
    residency,
    returnDate,
    utmCampaign,
    utmMedium,
    utmSource,
  } = params;

  const searchParams = constructSearchResultQueryPushParams({
    origins: [origin],
    destinations: [destination],
    residency: residency as `string` | undefined,
    currency: currency as CurrencyCode,
    utmSource: utmSource as `string` | undefined,
    utmMedium: utmMedium as `string` | undefined,
    utmCampaign: utmCampaign as `string` | undefined,
    isOneWay,
    departureDate: dayjs(departureDate),
    returnDate: dayjs(returnDate),
    paxTypeAges,
    steps,
  });

  return `${searchParams.pathname}?${new URLSearchParams(
    searchParams.query as Record<string, string>,
  )}`;
};

export const getOfferWithUpdatedPassenger = ({
  offer,
  passenger,
}: {
  offer: OfferResponse;
  passenger: Passenger;
}) => {
  return {
    ...offer,
    passengers: offer.passengers.map((pax) =>
      pax.passenger_id === passenger.passenger_id ? passenger : pax,
    ),
  };
};

export const updateOfferWithServiceQuantity = ({
  offer,
  quantity,
  serviceId,
}: {
  offer: OfferResponse;
  quantity: number;
  serviceId: string;
}) => {
  return {
    ...offer,
    service_groups: offer.service_groups.map((serviceGroup) => ({
      ...serviceGroup,
      services: serviceGroup.services.map((service) =>
        service.service_id === serviceId
          ? { ...service, quantity, can_decrement: false, can_increment: false }
          : service,
      ),
    })),
  };
};

export const getRouteType = (legs: Leg[]) => {
  const outboundLegs = legs.filter((leg) => leg.is_outbound);
  const homeboundLegs = legs.filter((leg) => !leg.is_outbound);

  if (
    (homeboundLegs.length === 0 && outboundLegs.length > 0) ||
    (outboundLegs.length === 0 && homeboundLegs.length > 0)
  ) {
    return 'oneway';
  } else if (
    tail(outboundLegs)?.destination.airport_iata ===
      head(homeboundLegs)?.origin.airport_iata &&
    tail(homeboundLegs)?.destination.airport_iata ===
      head(outboundLegs)?.origin.airport_iata
  ) {
    return 'roundtrip';
  }

  return 'multicity';
};

export const getCityNamesFromLegs = (legs: Leg[]) => {
  if (legs.length === 0) {
    return '';
  }

  const outboundLegs = legs.filter((leg) => leg.is_outbound);
  const homeboundLegs = legs.filter((leg) => !leg.is_outbound);

  const firstLeg = outboundLegs.length > 0 ? outboundLegs[0] : homeboundLegs[0];
  const lastLeg =
    outboundLegs.length > 0
      ? outboundLegs[outboundLegs.length - 1]
      : homeboundLegs[homeboundLegs.length - 1];

  if (!firstLeg || !lastLeg) {
    return '';
  }

  const isRoundtrip = getRouteType(legs) === 'roundtrip';

  if (isRoundtrip || homeboundLegs.length === 0 || outboundLegs.length === 0) {
    return `${firstLeg.origin.city_name} (${firstLeg.origin.airport_iata}) - ${lastLeg.destination.city_name} (${lastLeg.destination.airport_iata})`;
  }

  return `${firstLeg.origin.city_name} (${firstLeg.origin.airport_iata}) - ${
    lastLeg.destination.city_name
  } (${lastLeg.destination.airport_iata}), ${
    head(homeboundLegs)?.origin.city_name
  } (${head(homeboundLegs)?.origin.airport_iata}) - ${
    tail(homeboundLegs)?.destination.city_name
  } (${tail(homeboundLegs)?.destination.airport_iata})`;
};

export const getPassengerServiceMismatch = (
  passengerServiceGroups: PassengerServiceGroup[],
  includedServices: BundleService[],
) => {
  const additional = passengerServiceGroups
    .flatMap((sg) => sg.services)
    .filter((service) => (service.quantity ?? 0) > 0);

  return [...includedServices, ...additional].reduce<{
    [key: string]: BundleService;
  }>((acc, curr) => {
    if (!curr.serviceClass) {
      return acc;
    }

    return {
      ...acc,
      [curr.serviceClass]: {
        ...curr,
        quantity:
          (curr.quantity ?? 0) + (acc[curr.serviceClass]?.quantity ?? 0),
      },
    };
  }, {});
};

export const getServiceMismatch = ({
  bundleGroups,
  getFallbackIcon,
  iata,
  passengersServices,
  paxId,
  serviceClasses,
  t,
  vendor,
}: {
  bundleGroups: BundleGroup[];
  getFallbackIcon: (
    iconIdentifier: keyof IconConfigFragment,
  ) => ImageWithConfigFragment | null;
  iata: string;
  passengersServices: PassengerServiceGroup[];
  paxId: string;
  serviceClasses: ServiceClass[];
  t: TranslateCmsString;
  vendor?: Vendor;
}) => {
  const cmsServices =
    vendor?.vendorBookingConfig?.servicesConfig?.services ?? [];

  const selectedBundle = getSelectedBundleFromIata(bundleGroups, iata);

  const offerServices = getFilteredServices(
    serviceClasses,
    selectedBundle?.services,
  );

  const includedServices = constructCombinedServices({
    offerServices,
    getFallbackIcon,
    t,
    cmsServices,
  });

  const passengerServiceGroups = passengersServices.filter(
    (serviceGroup) =>
      serviceGroup.passenger.passenger_id === paxId &&
      (!iata || serviceGroup.iata === iata),
  );

  const passengerServiceMismatch = getPassengerServiceMismatch(
    passengerServiceGroups,
    includedServices,
  );

  return passengerServiceMismatch;
};

export const hasServiceMismatch = ({
  offer,
  serviceClasses,
}: {
  offer?: Maybe<OfferResponse>;
  serviceClasses: ServiceClass[];
}) => {
  if (!offer) {
    return false;
  }

  const { bundle_groups, passengers, service_groups, summary } = offer;
  const carrierCodes = getIatasFromSummary(summary);

  return serviceClasses.some((serviceClass) =>
    passengers.some((passenger) => {
      const passengerServiceGroups = service_groups.filter(
        (serviceGroup) => passenger.passenger_id === serviceGroup.passenger_id,
      );

      const quantities = carrierCodes.map((code) => {
        const serviceGroupsWithCarrierCode = passengerServiceGroups.filter(
          (serviceGroup) => serviceGroup.carrier_codes.includes(code),
        );

        const selectedBundle = getSelectedBundleFromIata(bundle_groups, code);
        const includedServices = getFilteredServices(
          serviceClasses,
          selectedBundle?.services,
        );

        if (serviceGroupsWithCarrierCode.length === 0) {
          return 0;
        }

        return (
          serviceGroupsWithCarrierCode.reduce<number>(
            (acc, serviceGroup) =>
              acc +
              serviceGroup.services.reduce<number>((serviceAcc, service) => {
                if (service.service_class !== serviceClass) {
                  return serviceAcc;
                }

                return serviceAcc + service.quantity;
              }, 0),
            0,
          ) +
          includedServices.reduce<number>(
            (acc, service) => acc + service.quantity,
            0,
          )
        );
      });

      return new Set(quantities).size > 1;
    }),
  );
};

export const getServiceFromServiceId = (
  serviceId: string,
  serviceGroups?: ServiceGroup[],
) =>
  serviceGroups?.reduce<ServiceGroupServicesItem | undefined>(
    (accService, group) => {
      const service = group.services.find((s) => s.service_id === serviceId);

      if (service && !accService) {
        return service;
      }

      return service;
    },
    undefined,
  );

export const getStepTitle = ({
  activeStepTitle,
  platformName,
  query,
  vendorPassengerRules,
}: {
  activeStepTitle: string;
  platformName: string;
  query: ParsedUrlQuery;
  vendorPassengerRules: PassengerRulesFragment | null;
}) => {
  const stepTitle = `${activeStepTitle} | ${platformName}`;

  const params = parseBookingDeeplink(query, vendorPassengerRules);

  if (!params) {
    return stepTitle;
  }

  const { origin } = params;
  const { destination } = params;

  return `${origin} - ${destination} | ${stepTitle}`;
};

export const bookingGetStaticProps = async (context: GetStaticPropsContext) => {
  const partner = context.params?.partner as Partner;
  const { queryClient } = await defaultGetStaticProps(context);

  const dehydratedState = dehydrate(queryClient);

  return {
    props: {
      dehydratedState,
      partner,
    },
    revalidate: DEFAULT_REVALIDATE_TIME,
  };
};

export const getItineraryInfoFromQueryParams = (query: ParsedUrlQuery) => {
  return {
    isRoundTrip: Boolean(query.home),
    numberOfVendors: Array.isArray(query.fares)
      ? query.fares.length
      : query.fares?.split('--').length || 1,
    outboundStops: parseQueryString(query.out).split(LEG_SPLIT).length - 1,
    homeboundStops: parseQueryString(query.home).split(LEG_SPLIT).length - 1,
  };
};

/**
 * Determines whether an notification section should be shown.
 * Notification sections have an optional field to determine
 * when the section should be visible based on the user's trip type.
 */
export const isNotificationSectionActive = ({
  activeDuringTripType,
  dohopServiceClass,
}: {
  activeDuringTripType?: string[] | null;
  dohopServiceClass: 'dohop_service_fee' | 'dohop_commission_fee' | null;
}) => {
  // If no trip types have been selected or the service class is not found, show the section by default
  if (
    !activeDuringTripType ||
    activeDuringTripType.length === 0 ||
    !dohopServiceClass
  ) {
    return true;
  }

  /**
   * If this is a dohop commission trip and mixed metal is not included
   * in the active during trip type field, hide the field
   */
  if (
    dohopServiceClass === 'dohop_commission_fee' &&
    !activeDuringTripType.includes('mixed-metal')
  ) {
    return false;
  }

  /**
   * If this is a dohop fee trip, we show the field for self-connect and ground-transit
   */
  if (
    dohopServiceClass === 'dohop_service_fee' &&
    !(
      activeDuringTripType.includes('self-connect') ||
      activeDuringTripType.includes('ground-transit')
    )
  ) {
    return false;
  }

  // Show the field if none of the criteria match
  return true;
};

const bookingRoutes = Object.values(Route).filter(
  (route) =>
    ![
      Route.Index,
      Route.BotDetect,
      Route.Error,
      Route.NotFound,
      Route.Confirmation,
      Route.MyBooking,
      Route.Search,
      Route.CreateOrder,
    ].includes(route),
);

export const isBookingRoute = (pathname: string) =>
  bookingRoutes.some((route) => pathname.includes(route));

export const getPassengersWithCorrectIds = ({
  offer,
  passengers,
}: {
  offer?: OfferResponse;
  passengers?: (Passenger & { passenger_type?: PaxType })[];
}) =>
  passengers?.map((pax: Passenger, index: number) => {
    return {
      ...pax,
      passenger_id: offer?.passengers[index]?.passenger_id ?? index.toString(),
    };
  });
