import {
  createContext,
  ReactNode,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { useGetCurrencyExchangeRateQuery } from '@codegen/gatewayUtils';
import { BaseAnalyticsProvider } from '@ui-v2/utils/contexts/BaseAnalyticsContext';
import {
  modalShown,
  popupShown,
  tooltipShown,
  pciproxyLoaded,
  passengerInfoStarted,
  passengerInfoSubmitted,
  billingAddressSubmitted,
  billingAddressStarted,
  contactInfoStarted,
  contactInfoSubmitted,
  partialPassengerInfoStarted,
  partialPassengerInfoSubmitted,
  paymentFormStarted,
  productViewedError,
  orderPriceMismatch,
  buttonClicked,
  clearJourneySelection,
} from '@web/utils/analytics/Avo';
import useAnalyticsQueue from './hooks/useAnalyticsQueue';
import useCreateGlobalAnalyticsClickHandlers from './hooks/useCreateGlobalAnalyticsClickHandlers';
import { useSettings } from './SettingsContext';
import {
  sendProductViewedEvent,
  sendProductClickedEvent,
  sendPriceMismatchEvent,
  sendAddServiceEvent,
  sendServiceMismatchEvent,
  sendUpdatePassengersEvent,
  sendOrderCompletedEvent,
  sendPaymentInfoEnteredEvent,
  sendSearchResultAnalyticsEvent,
  sendBundleSelectedEvent,
  sendCreateOrderErrorEvent,
  normalizeCurrency,
} from './utils/analyticsContextUtils';
import '@web/utils/analytics/AvoRudderstackBridge';

export type Analytics = {
  processAnalyticsQueue: () => void;
  sendAddServiceEvent: (
    params: Omit<
      Parameters<typeof sendAddServiceEvent>[0],
      'currencyConversionRate'
    >,
  ) => void;
  sendBillingAddressStartedEvent: typeof billingAddressStarted;
  sendBillingAddressSubmittedEvent: typeof billingAddressSubmitted;
  sendBundleSelectedEvent: (
    params: Omit<
      Parameters<typeof sendBundleSelectedEvent>[0],
      'currencyConversionRate'
    >,
  ) => void;
  sendButtonClickedEvent: typeof buttonClicked;
  sendClearJourneySelectionEvent: typeof clearJourneySelection;
  sendContactInfoStartedEvent: typeof contactInfoStarted;
  sendContactInfoSubmittedEvent: typeof contactInfoSubmitted;
  sendCreateOrderErrorEvent: typeof sendCreateOrderErrorEvent;
  sendOrderCompletedEvent: (
    params: Omit<
      Parameters<typeof sendOrderCompletedEvent>[0],
      'currencyConversionRate'
    >,
  ) => void;
  sendOrderPriceMismatch: (
    params: Omit<
      Parameters<typeof orderPriceMismatch>[0],
      'oldPriceNormalized' | 'newPriceNormalized'
    >,
  ) => void;
  sendPCIProxyLoadedEvent: typeof pciproxyLoaded;
  sendPartialPassengerInfoStartedEvent: typeof partialPassengerInfoStarted;
  sendPartialPassengerInfoSubmittedEvent: typeof partialPassengerInfoSubmitted;
  sendPassengerInfoStartedEvent: typeof passengerInfoStarted;
  sendPassengerInfoSubmittedEvent: typeof passengerInfoSubmitted;
  sendPaymentFormStartedEvent: typeof paymentFormStarted;
  sendPaymentInfoEnteredEvent: (
    params: Omit<
      Parameters<typeof sendPaymentInfoEnteredEvent>[0],
      'currencyConversionRate'
    >,
  ) => void;
  sendPriceMismatchEvent: (
    params: Omit<
      Parameters<typeof sendPriceMismatchEvent>[0],
      'currencyConversionRate'
    >,
  ) => void;
  sendProductClickedEvent: (
    params: Omit<
      Parameters<typeof sendProductClickedEvent>[0],
      'currencyConversionRate'
    >,
  ) => void;
  sendProductViewedError: typeof productViewedError;
  sendProductViewedEvent: (
    params: Omit<
      Parameters<typeof sendProductViewedEvent>[0],
      'currencyConversionRate'
    >,
  ) => void;
  sendSearchResultAnalyticsEvent: (
    params: Omit<
      Parameters<typeof sendSearchResultAnalyticsEvent>[0],
      'currencyConversionRate'
    >,
  ) => void;
  sendServiceMismatchEvent: typeof sendServiceMismatchEvent;
  sendUpdatePassengersEvent: typeof sendUpdatePassengersEvent;
};

const context = createContext<Analytics>({
  sendSearchResultAnalyticsEvent: () => {},
  sendProductViewedEvent: () => {},
  sendProductClickedEvent: () => {},
  sendPriceMismatchEvent: () => {},
  sendAddServiceEvent: () => {},
  sendServiceMismatchEvent: () => {},
  sendUpdatePassengersEvent: () => {},
  sendOrderCompletedEvent: () => {},
  sendPaymentInfoEnteredEvent: () => {},
  sendBundleSelectedEvent: () => {},
  processAnalyticsQueue: () => {},
  sendPCIProxyLoadedEvent: () => {},
  sendBillingAddressSubmittedEvent: () => {},
  sendBillingAddressStartedEvent: () => {},
  sendContactInfoSubmittedEvent: () => {},
  sendContactInfoStartedEvent: () => {},
  sendPartialPassengerInfoSubmittedEvent: () => {},
  sendPartialPassengerInfoStartedEvent: () => {},
  sendPassengerInfoSubmittedEvent: () => {},
  sendPaymentFormStartedEvent: () => {},
  sendPassengerInfoStartedEvent: () => {},
  sendProductViewedError: () => {},
  sendOrderPriceMismatch: () => {},
  sendCreateOrderErrorEvent: () => {},
  sendButtonClickedEvent: () => {},
  sendClearJourneySelectionEvent: () => {},
});

export default context;

export const AnalyticsProvider = ({ children }: { children: ReactNode }) => {
  const { processAnalyticsQueue, pushToAnalyticsQueue } = useAnalyticsQueue();
  const { currency } = useSettings();
  const { data } = useGetCurrencyExchangeRateQuery({ currencyCode: currency });

  const currencyConversionRate = useMemo(
    () => data?.getCurrencyExchangeRate || 1,
    [data?.getCurrencyExchangeRate],
  );

  useCreateGlobalAnalyticsClickHandlers();

  const sendProductViewedEventCallback = useCallback(
    (params: Parameters<Analytics['sendProductViewedEvent']>[0]) =>
      pushToAnalyticsQueue(() =>
        sendProductViewedEvent({ ...params, currencyConversionRate }),
      ),
    [currencyConversionRate, pushToAnalyticsQueue],
  );

  const sendProductClickedEventCallback = useCallback(
    (params: Parameters<Analytics['sendProductClickedEvent']>[0]) =>
      pushToAnalyticsQueue(() =>
        sendProductClickedEvent({ ...params, currencyConversionRate }),
      ),
    [currencyConversionRate, pushToAnalyticsQueue],
  );

  const sendPriceMismatchEventCallback = useCallback(
    (params: Parameters<Analytics['sendPriceMismatchEvent']>[0]) =>
      pushToAnalyticsQueue(() =>
        sendPriceMismatchEvent({ ...params, currencyConversionRate }),
      ),
    [currencyConversionRate, pushToAnalyticsQueue],
  );

  const sendAddServiceEventCallback = useCallback(
    (params: Parameters<Analytics['sendAddServiceEvent']>[0]) =>
      pushToAnalyticsQueue(() =>
        sendAddServiceEvent({ ...params, currencyConversionRate }),
      ),
    [currencyConversionRate, pushToAnalyticsQueue],
  );

  const sendServiceMismatchEventCallback = useCallback(
    (...params: Parameters<typeof sendServiceMismatchEvent>) =>
      pushToAnalyticsQueue(() => sendServiceMismatchEvent(...params)),
    [pushToAnalyticsQueue],
  );

  const sendUpdatePassengersEventCallback = useCallback(
    (...params: Parameters<typeof sendUpdatePassengersEvent>) =>
      pushToAnalyticsQueue(() => sendUpdatePassengersEvent(...params)),
    [pushToAnalyticsQueue],
  );

  const sendOrderCompletedEventCallback = useCallback(
    (params: Parameters<Analytics['sendOrderCompletedEvent']>[0]) =>
      pushToAnalyticsQueue(() =>
        sendOrderCompletedEvent({ ...params, currencyConversionRate }),
      ),
    [currencyConversionRate, pushToAnalyticsQueue],
  );

  const sendPaymentInfoEnteredEventCallback = useCallback(
    (params: Parameters<Analytics['sendPaymentInfoEnteredEvent']>[0]) =>
      pushToAnalyticsQueue(() =>
        sendPaymentInfoEnteredEvent({ ...params, currencyConversionRate }),
      ),
    [currencyConversionRate, pushToAnalyticsQueue],
  );

  const sendBundleSelectedEventCallback = useCallback(
    (params: Parameters<Analytics['sendBundleSelectedEvent']>[0]) =>
      pushToAnalyticsQueue(() =>
        sendBundleSelectedEvent({ ...params, currencyConversionRate }),
      ),
    [currencyConversionRate, pushToAnalyticsQueue],
  );

  const sendSearchResultAnalyticsEventCallback = (
    params: Parameters<Analytics['sendSearchResultAnalyticsEvent']>[0],
  ) =>
    pushToAnalyticsQueue(() =>
      sendSearchResultAnalyticsEvent({ ...params, currencyConversionRate }),
    );

  const sendModalShownEventCallback = useCallback(
    (...params: Parameters<typeof modalShown>) =>
      pushToAnalyticsQueue(() => modalShown(...params)),
    [pushToAnalyticsQueue],
  );

  const sendPopupShownEventCallback = useCallback(
    (...params: Parameters<typeof popupShown>) =>
      pushToAnalyticsQueue(() => popupShown(...params)),
    [pushToAnalyticsQueue],
  );

  const sendTooltipShownEventCallback = useCallback(
    (...params: Parameters<typeof tooltipShown>) =>
      pushToAnalyticsQueue(() => tooltipShown(...params)),
    [pushToAnalyticsQueue],
  );

  const sendPCIProxyLoadTimeEventCallback = useCallback(
    (...params: Parameters<typeof pciproxyLoaded>) =>
      pushToAnalyticsQueue(() => pciproxyLoaded(...params)),
    [pushToAnalyticsQueue],
  );

  const sendPassengerInfoStartedEventCallback = useCallback(
    (...params: Parameters<typeof passengerInfoStarted>) =>
      pushToAnalyticsQueue(() => passengerInfoStarted(...params)),
    [pushToAnalyticsQueue],
  );

  const sendPassengerInfoSubmittedEventCallback = useCallback(
    (...params: Parameters<typeof passengerInfoSubmitted>) =>
      pushToAnalyticsQueue(() => passengerInfoSubmitted(...params)),
    [pushToAnalyticsQueue],
  );

  const sendBillingAddressSubmittedEventCallback = useCallback(
    (...params: Parameters<typeof billingAddressSubmitted>) =>
      pushToAnalyticsQueue(() => billingAddressSubmitted(...params)),
    [pushToAnalyticsQueue],
  );

  const sendBillingAddressStartedEventCallback = useCallback(
    (...params: Parameters<typeof billingAddressStarted>) =>
      pushToAnalyticsQueue(() => billingAddressStarted(...params)),
    [pushToAnalyticsQueue],
  );

  const sendContactInfoStartedEventCallback = useCallback(
    (...params: Parameters<typeof contactInfoStarted>) =>
      pushToAnalyticsQueue(() => contactInfoStarted(...params)),
    [pushToAnalyticsQueue],
  );

  const sendContactInfoSubmittedEventCallback = useCallback(
    (...params: Parameters<typeof contactInfoSubmitted>) =>
      pushToAnalyticsQueue(() => contactInfoSubmitted(...params)),
    [pushToAnalyticsQueue],
  );

  const sendPartialPassengerInfoStartedEventCallback = useCallback(
    (...params: Parameters<typeof partialPassengerInfoStarted>) =>
      pushToAnalyticsQueue(() => partialPassengerInfoStarted(...params)),
    [pushToAnalyticsQueue],
  );

  const sendPartialPassengerInfoSubmittedEventCallback = useCallback(
    (...params: Parameters<typeof partialPassengerInfoSubmitted>) =>
      pushToAnalyticsQueue(() => partialPassengerInfoSubmitted(...params)),
    [pushToAnalyticsQueue],
  );

  const sendPaymentFormStartedEventCallback = useCallback(
    (...params: Parameters<typeof paymentFormStarted>) =>
      pushToAnalyticsQueue(() => paymentFormStarted(...params)),
    [pushToAnalyticsQueue],
  );

  const sendProductViewedErrorCallback = useCallback(
    (...params: Parameters<typeof productViewedError>) =>
      pushToAnalyticsQueue(() => productViewedError(...params)),
    [pushToAnalyticsQueue],
  );

  const sendOrderPriceMismatch = useCallback(
    (params: Parameters<Analytics['sendOrderPriceMismatch']>[0]) =>
      pushToAnalyticsQueue(() =>
        orderPriceMismatch({
          ...params,
          oldPriceNormalized: normalizeCurrency({
            price: params.oldPrice,
            currencyConversionRate,
          }),
          newPriceNormalized: normalizeCurrency({
            price: params.newPrice,
            currencyConversionRate,
          }),
        }),
      ),
    [currencyConversionRate, pushToAnalyticsQueue],
  );

  const sendCreateOrderErrorEventCallback = useCallback(
    (...params: Parameters<typeof sendCreateOrderErrorEvent>) =>
      pushToAnalyticsQueue(() => sendCreateOrderErrorEvent(...params)),
    [pushToAnalyticsQueue],
  );

  const sendButtonClickedEventCallback = useCallback(
    (...params: Parameters<typeof buttonClicked>) =>
      pushToAnalyticsQueue(() => buttonClicked(...params)),
    [pushToAnalyticsQueue],
  );

  const sendClearJourneySelectionEventCallback = useCallback(
    (...params: Parameters<typeof clearJourneySelection>) =>
      pushToAnalyticsQueue(() => clearJourneySelection(...params)),
    [pushToAnalyticsQueue],
  );

  return (
    <context.Provider
      value={{
        sendProductViewedEvent: sendProductViewedEventCallback,
        sendProductClickedEvent: sendProductClickedEventCallback,
        sendPriceMismatchEvent: sendPriceMismatchEventCallback,
        sendAddServiceEvent: sendAddServiceEventCallback,
        sendServiceMismatchEvent: sendServiceMismatchEventCallback,
        sendUpdatePassengersEvent: sendUpdatePassengersEventCallback,
        sendOrderCompletedEvent: sendOrderCompletedEventCallback,
        sendPaymentInfoEnteredEvent: sendPaymentInfoEnteredEventCallback,
        sendBundleSelectedEvent: sendBundleSelectedEventCallback,
        sendSearchResultAnalyticsEvent: sendSearchResultAnalyticsEventCallback,
        sendPCIProxyLoadedEvent: sendPCIProxyLoadTimeEventCallback,
        sendPassengerInfoStartedEvent: sendPassengerInfoStartedEventCallback,
        sendPassengerInfoSubmittedEvent:
          sendPassengerInfoSubmittedEventCallback,
        sendBillingAddressSubmittedEvent:
          sendBillingAddressSubmittedEventCallback,
        sendBillingAddressStartedEvent: sendBillingAddressStartedEventCallback,
        sendContactInfoStartedEvent: sendContactInfoStartedEventCallback,
        sendContactInfoSubmittedEvent: sendContactInfoSubmittedEventCallback,
        sendPartialPassengerInfoStartedEvent:
          sendPartialPassengerInfoStartedEventCallback,
        sendPartialPassengerInfoSubmittedEvent:
          sendPartialPassengerInfoSubmittedEventCallback,
        sendPaymentFormStartedEvent: sendPaymentFormStartedEventCallback,
        sendProductViewedError: sendProductViewedErrorCallback,
        processAnalyticsQueue,
        sendOrderPriceMismatch,
        sendCreateOrderErrorEvent: sendCreateOrderErrorEventCallback,
        sendButtonClickedEvent: sendButtonClickedEventCallback,
        sendClearJourneySelectionEvent: sendClearJourneySelectionEventCallback,
      }}
    >
      <BaseAnalyticsProvider
        sendModalShownEvent={sendModalShownEventCallback}
        sendPopupShownEvent={sendPopupShownEventCallback}
        sendTooltipShownEvent={sendTooltipShownEventCallback}
      >
        {children}
      </BaseAnalyticsProvider>
    </context.Provider>
  );
};

export const useAnalytics = () => useContext(context);
