import { Vendors } from '@codegen/cmsTypes';
import { TranslateCmsString } from '@utils/hooks/useCmsTranslation';
import { FieldValidation, getFieldValidation } from './passengerUtils';

export type BillingAddressValidations = {
  address: FieldValidation;
  city: FieldValidation;
  country: FieldValidation;
  postalCode: FieldValidation;
};

const getDefaultBillingAddressValidation = (t: TranslateCmsString) => ({
  address: {
    required: true,
    minLength: 1,
    maxLength: Number.MAX_SAFE_INTEGER,
    regex: [],
  },
  city: {
    required: true,
    minLength: 1,
    maxLength: Number.MAX_SAFE_INTEGER,
    regex: [],
  },
  postalCode: {
    required: true,
    minLength: 1,
    maxLength: 10,
    regex: [
      {
        // eslint-disable-next-line no-useless-escape
        pattern: `\\s+`,
        errorMessage: {
          __typename: 'ApplicationStringRecord' as const,
          value: t('postal_code_no_white_space', 'White space not allowed'),
          stringId: '',
          id: '',
        },
      },
    ],
  },
  country: {
    required: true,
    minLength: 1,
    maxLength: Number.MAX_SAFE_INTEGER,
    regex: [],
  },
});

export const getBillingAddressValidations = ({
  t,
  vendors,
}: {
  t: TranslateCmsString;
  vendors: Vendors;
}) => {
  return Object.values(vendors).reduce<BillingAddressValidations>(
    (acc, { billingAddressValidation }) => {
      if (!billingAddressValidation) {
        return acc;
      }

      return {
        address: getFieldValidation(
          acc.address,
          billingAddressValidation.address,
        ),
        city: getFieldValidation(acc.city, billingAddressValidation.city),
        postalCode: getFieldValidation(
          acc.postalCode,
          billingAddressValidation.postalCode,
        ),
        country: getFieldValidation(
          acc.country,
          billingAddressValidation.country,
        ),
      };
    },
    getDefaultBillingAddressValidation(t),
  );
};
