import dayjs from 'dayjs';
import { z } from 'zod';
import { PassengerRulesFragment } from '@codegen/cmsUtils';
import { Passenger, PaxGender, PaxType } from '@codegen/offerAPI';
import { PassengerFields } from '@shared/types/enums';
import { validateDate } from '@ui/components/FormInput/Utils/expiryDateUtils';
import { TranslateCmsString } from '@utils/hooks/useCmsTranslation';
import { BillingAddressValidations } from './billingAddressUtils';
import { ContactValidations } from './contactUtils';
import { PASSENGER_FIELDS } from './formUtils';
import {
  FieldValidation,
  INITIAL_PASSENGER_VALIDATION,
  PassengerValidations,
  checkIfAgeIsValid,
} from './passengerUtils';

type PassengerFieldsMap = {
  [key in PassengerFields]?: true;
};

export const getZodExpiryDateObject = (
  t: TranslateCmsString,
  errorMessage?: string,
) =>
  z
    .string({ required_error: errorMessage })
    .min(1, errorMessage)
    .superRefine((value, { addIssue }) => {
      const errorMessage = validateDate(t, value);

      if (errorMessage) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errorMessage,
        });
      }
    });

export const getZodFieldValidation = (
  zField: z.ZodString,
  t: TranslateCmsString,
  { maxLength, minLength, regex, required }: FieldValidation,
  additionalValidation = (val: z.ZodString) => val,
) =>
  additionalValidation(
    zField
      .min(
        required ? 1 : 0,
        t('This field is required', 'This field is required'),
      )
      .min(minLength, t('This field is too short', 'This field is too short'))
      .max(maxLength, t('Field to long', 'Field to long')),
  ).superRefine((val, { addIssue }) => {
    regex.forEach(({ errorMessage, modifiers, pattern }) => {
      if (val.match(new RegExp(pattern, modifiers ?? undefined))) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errorMessage.value,
        });
      }
    });
  });

export const getZodPassengersObject = ({
  lastFlightDate,
  passengerFieldsToShow,
  t,
  validations,
  vendorPassengerRules,
}: {
  lastFlightDate?: string;
  passengerFieldsToShow: string[];
  t: TranslateCmsString;
  validations: PassengerValidations;
  vendorPassengerRules: PassengerRulesFragment;
}) => {
  const optionalFields = PASSENGER_FIELDS.reduce<PassengerFieldsMap>(
    (acc, field) => {
      if (
        !passengerFieldsToShow.includes(field) &&
        field !== PassengerFields.PASSENGER_TYPE
      ) {
        return { ...acc, [field]: true };
      }

      return acc;
    },
    {},
  );

  return z
    .object({
      [PassengerFields.GENDER]: z.nativeEnum(PaxGender, {
        required_error: t('This field is required', 'This field is required'),
      }),
      [PassengerFields.TITLE]: getZodFieldValidation(
        z.string({
          required_error: t('This field is required', 'This field is required'),
        }),
        t,
        validations.title,
      ),
      [PassengerFields.FIRST_NAME]: getZodFieldValidation(
        z.string({
          required_error: t('This field is required', 'This field is required'),
        }),
        t,
        INITIAL_PASSENGER_VALIDATION.firstName,
      ),
      [PassengerFields.LAST_NAME]: getZodFieldValidation(
        z.string({
          required_error: t('This field is required', 'This field is required'),
        }),
        t,
        validations.lastName,
      ),
      [PassengerFields.DATE_OF_BIRTH]: z
        .string({
          required_error: t('This field is required', 'This field is required'),
        })
        .min(
          validations.dateOfBirth.required ? 1 : 0,
          t('This field is required', 'This field is required'),
        )
        .refine(
          (val) => dayjs(val, 'YYYY-MM-DD', true).isValid(),
          t('Invalid date of birth', 'Invalid date of birth'),
        )
        .refine(
          (val) => dayjs(val, 'YYYY-MM-DD', true).isBefore(dayjs()),
          t('Invalid date of birth', 'Invalid date of birth'),
        ),
      [PassengerFields.PASSPORT_COUNTRY]: z
        .string({
          required_error: t('This field is required', 'This field is required'),
        })
        .min(1, t('This field is required', 'This field is required')),
      [PassengerFields.PASSPORT_EXPIRATION]: z
        .string({
          required_error: t('This field is required', 'This field is required'),
        })
        .refine(
          (val) => dayjs(val, 'YYYY-MM-DD', true).isValid(),
          t(
            'Invalid passport expiration date',
            'Invalid passport expiration date',
          ),
        ),
      [PassengerFields.PASSPORT_NUMBER]: z
        .string({
          required_error: t('This field is required', 'This field is required'),
        })
        .min(1, t('This field is required', 'This field is required')),
      [PassengerFields.FREQUENT_FLYER]: z
        .record(
          z.string({
            required_error: t(
              'This field is required',
              'This field is required',
            ),
          }),
          z.string({
            required_error: t(
              'This field is required',
              'This field is required',
            ),
          }),
        )
        .optional(),
      [PassengerFields.PASSENGER_TYPE]: z.nativeEnum(PaxType, {
        required_error: t('This field is required', 'This field is required'),
      }),
    })
    .partial(optionalFields)
    .superRefine((val, { addIssue }) => {
      const { errorMessage, isInvalidAge } = checkIfAgeIsValid({
        type: val.passenger_type,
        dateOfBirth: val.date_of_birth,
        lastFlightDate,
        vendorPassengerRules,
        t,
      });

      // if date of birth is optional, we can't validate the age
      if (isInvalidAge && !optionalFields.date_of_birth) {
        addIssue({
          code: z.ZodIssueCode.custom,
          path: [PassengerFields.DATE_OF_BIRTH],
          message: errorMessage,
        });
      }
    });
};

export const getZodContactObject = ({
  t,
  validations,
}: {
  t: TranslateCmsString;
  validations: ContactValidations;
}) =>
  z.object({
    contact: z
      .object({
        phone_country_code: getZodFieldValidation(
          z.string(),
          t,
          validations.phoneCountryCode,
        ),
        phone: getZodFieldValidation(z.string(), t, validations.phoneNumber),
        email: getZodFieldValidation(
          z.string(),
          t,
          validations.phoneCountryCode,
          (val: z.ZodString) =>
            val.email({
              message: t('Invalid email address', 'Invalid email address'),
            }),
        ),
      })
      .optional(),
  });

export const getZodBillingAddressObject = ({
  t,
  validations,
}: {
  t: TranslateCmsString;
  validations: BillingAddressValidations;
}) => {
  const provinceCountries = ['AU', 'US', 'CA'];

  return z.object({
    billingAddress: z
      .object({
        address: getZodFieldValidation(z.string(), t, validations.address),
        city: getZodFieldValidation(z.string(), t, validations.city),
        postal_code: getZodFieldValidation(
          z.string(),
          t,
          validations.postalCode,
        ),
        country: getZodFieldValidation(z.string(), t, validations.country),
        province: z.string(),
      })
      .refine(
        (val) => {
          // We only want to validate the province if the country is AU, US or CA
          if (
            provinceCountries.includes(val.country) &&
            val.province.length === 0
          ) {
            return false;
          }

          return true;
        },
        {
          message: t('This field is required', 'This field is required'),
          path: ['province'],
        },
      ),
  });
};

export const getZodBaggageObject = () => {
  return z.object({
    baggage: z.object({
      hasServiceMismatch: z.boolean().refine((val) => {
        return !val;
      }),
    }),
  });
};

export const getZodBillingInformationObject = () => {
  return z.object({
    billingInformation: z.object({
      termsAccepted: z.boolean().refine((val) => {
        return val === true;
      }),
    }),
  });
};

export type PassengersFormType = {
  passengers?: (Passenger & { passenger_type?: PaxType })[];
};

export type ContactInformationFormType = z.infer<
  ReturnType<typeof getZodContactObject>
>;

export type BillingAddressFormType = z.infer<
  ReturnType<typeof getZodBillingAddressObject>
>;

export type BaggageFormType = z.infer<ReturnType<typeof getZodBaggageObject>>;

export type BillingInformationFormType = z.infer<
  ReturnType<typeof getZodBillingInformationObject>
>;
