import { Vendors } from '@codegen/cmsTypes';
import { Passenger, PaxType } from '@codegen/offerAPI';
import { TranslateCmsString } from '@utils/hooks/useCmsTranslation';
import {
  FieldValidation,
  getFieldValidation,
  mapGenderToTitle,
} from './passengerUtils';

export type ContactValidations = {
  email: FieldValidation;
  phoneCountryCode: FieldValidation;
  phoneNumber: FieldValidation;
};

export const getContactInfoWithFirstAdult = ({
  contact,
  passengers,
}: {
  contact: {
    email: string;
    phone: string;
    phone_country_code: string;
  };
  passengers: Passenger[];
}) => {
  const firstAdult = passengers.find(
    (pax) => pax.expected_type === PaxType['a'],
  );

  if (
    !firstAdult ||
    !firstAdult.first_name ||
    !firstAdult.last_name ||
    (!firstAdult.title && !firstAdult.gender)
  ) {
    return null;
  }
  const title = firstAdult.title
    ? firstAdult.title.trim()
    : mapGenderToTitle(firstAdult.gender);

  return {
    phone: contact.phone.trim(),
    phone_country_code: contact.phone_country_code.trim(),
    email: contact.email.trim(),
    title,
    first_name: firstAdult.first_name.trim(),
    last_name: firstAdult.last_name.trim(),
  };
};

const getDefaultContactValidation = (t: TranslateCmsString) => ({
  phoneCountryCode: {
    required: true,
    minLength: 0,
    maxLength: Number.MAX_SAFE_INTEGER,
    regex: [],
  },
  phoneNumber: {
    required: true,
    minLength: 5,
    maxLength: 12,
    regex: [
      {
        pattern: '[^0-9]+',
        errorMessage: {
          __typename: 'ApplicationStringRecord' as const,
          value: t('Invalid phone number', 'Invalid phone number'),
          stringId: '',
          id: '',
        },
      },
    ],
  },
  email: {
    required: true,
    minLength: 0,
    maxLength: Number.MAX_SAFE_INTEGER,
    regex: [],
  },
});

export const getContactValidations = ({
  t,
  vendors,
}: {
  t: TranslateCmsString;
  vendors: Vendors;
}) => {
  return Object.values(vendors).reduce<ContactValidations>(
    (acc, { contactValidation }) => {
      if (!contactValidation) {
        return acc;
      }

      return {
        phoneCountryCode: getFieldValidation(
          acc.phoneCountryCode,
          contactValidation.phoneCountryCode,
        ),
        phoneNumber: getFieldValidation(
          acc.phoneNumber,
          contactValidation.phoneNumber,
        ),
        email: getFieldValidation(acc.email, contactValidation.email),
      };
    },
    getDefaultContactValidation(t),
  );
};
