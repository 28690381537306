import useCmsTranslation from '@utils/hooks/useCmsTranslation';
import useVendors from '@web/context/hooks/useVendors';
import { getBillingAddressValidations } from '@web/utils/booking/billingAddressUtils';
import { getContactValidations } from '@web/utils/booking/contactUtils';
import { getPassengerValidations } from '@web/utils/booking/passengerUtils';

const useBookingInputValidationRules = (iatas: string[]) => {
  const { t } = useCmsTranslation();
  const { vendors } = useVendors({ iatas, includeBookingData: true });

  return {
    passengerValidations: getPassengerValidations(vendors),
    contactValidations: getContactValidations({ t, vendors }),
    billingAddressValidations: getBillingAddressValidations({ t, vendors }),
  };
};

export default useBookingInputValidationRules;
