import { Vendors } from '@codegen/cmsTypes';
import { ItineraryFragment } from '@codegen/gatewayUtils';
import { Language } from '@shared/types/enums';
import Box from '@ui-v2/core/Box/Box';
import { head } from '@utils/arrayUtils';
import { toUTCLocaleString } from '@utils/dateUtils';
import useCmsTranslation from '@utils/hooks/useCmsTranslation';
import { GridRow, GridColumn } from '../Grid/Grid';
import Link from '../Link/Link';
import ItineraryDirection from './ItineraryDirection';

export interface Props {
  className?: string;
  dohopConnectName: string;
  hasNoCheckedBagsConnection?: boolean;
  itinerary: ItineraryFragment;
  language: Language;
  residency: string;
  showFareRules?: boolean;
  showItineraryVendorLogos: boolean;
  showSelfConnectList?: boolean;
  showSelfTransfer?: boolean;
  toggleInformationModal: () => void;
  vendors: Vendors;
}

const Itinerary = ({
  className,
  dohopConnectName,
  hasNoCheckedBagsConnection,
  itinerary,
  language,
  residency,
  showFareRules,
  showItineraryVendorLogos,
  showSelfConnectList,
  showSelfTransfer,
  toggleInformationModal,
  vendors,
}: Props) => {
  const { t } = useCmsTranslation();
  const { homebound, outbound } = itinerary;

  if (outbound.length === 0 && homebound.length === 0) {
    return null;
  }

  const firstOutbondFlight = head(outbound);

  const outboundTitle = firstOutbondFlight
    ? `${t('depart', 'Depart')} - ${toUTCLocaleString({
        date: firstOutbondFlight.departure.toDate(),
        locale: language,
        options: {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          weekday: 'short',
          hour12: false,
        },
      })}`
    : '';

  const homeboundTitle =
    homebound.length > 0
      ? `${t('return', 'Return')} - ${
          homebound[0]?.departure
            ? toUTCLocaleString({
                date: homebound[0]?.departure.toDate(),
                locale: language,
                options: {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                  weekday: 'short',
                  hour12: false,
                },
              })
            : ''
        }`
      : '';

  const vendorsWithFaresLink = vendors.filter(
    (vendor) => vendor.aboutFaresLink,
  );

  return (
    <Box
      className={className}
      display="flex"
      flexDirection="column"
      rowGap={24}
    >
      <Box display="flex" flexWrap="wrap" gap={48}>
        {outbound.length > 0 && (
          <ItineraryDirection
            directionId="outbound"
            dohopConnectName={dohopConnectName}
            hasNoCheckedBagsConnection={hasNoCheckedBagsConnection}
            language={language}
            residency={residency}
            routes={outbound}
            showItineraryVendorLogos={showItineraryVendorLogos}
            showSelfConnectList={showSelfConnectList}
            showSelfTransfer={showSelfTransfer}
            title={outboundTitle}
            toggleInformationModal={toggleInformationModal}
            vendors={vendors}
          />
        )}
        {homebound.length > 0 && (
          <ItineraryDirection
            directionId="homebound"
            dohopConnectName={dohopConnectName}
            hasNoCheckedBagsConnection={hasNoCheckedBagsConnection}
            language={language}
            residency={residency}
            routes={homebound}
            showItineraryVendorLogos={showItineraryVendorLogos}
            showSelfConnectList={showSelfConnectList}
            showSelfTransfer={showSelfTransfer}
            title={homeboundTitle}
            toggleInformationModal={toggleInformationModal}
            vendors={vendors}
          />
        )}
      </Box>

      {showFareRules && vendorsWithFaresLink.length > 0 && (
        <Box borderTop="subdued" pt={16}>
          {vendorsWithFaresLink.map(({ aboutFaresLink, id, name }) =>
            aboutFaresLink?.link ? (
              <GridRow key={id}>
                <GridColumn px={0}>
                  <Link href={aboutFaresLink.link} target="__blank">
                    {t('About airline fares', `About ${name} fares`, {
                      airline: name,
                    })}
                  </Link>
                </GridColumn>
              </GridRow>
            ) : null,
          )}
        </Box>
      )}
    </Box>
  );
};

export default Itinerary;
