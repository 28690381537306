import { ParsedUrlQuery } from 'querystring';
import { createContext, useContext, useEffect, useState } from 'react';
import { BookingStepFragment } from '@codegen/cmsUtils';
import { parseBookingDeeplink } from '@web/utils/booking/bookingUtils';
import useBookingConfig from './hooks/useBookingConfig';
import usePartnerRouter from './hooks/usePartnerRouter';
import usePartnerPassengersConfig from './hooks/usePassengersConfig';
import useVendors from './hooks/useVendors';
import { shouldShowSeatmapForVendors } from './utils/stepContextUtils';

export interface InTransition {
  next: boolean;
  previous: boolean;
}

export type Step = {
  activeStepIndex: number;
  firstBookingStepIndex: number;
  hasClickedNextButton: boolean;
  hasNavigatedFromFirstStep: boolean | null;
  hasUpdatedPassengers: boolean;
  inTransition: InTransition;
  isFirstBookingStep: boolean;
  isLastStep: boolean;
  setHasClickedNextButton: (hasClickedNext: boolean) => void;
  setHasNavigatedFromFirstStep: (hasClickedNext: boolean) => void;
  setHasUpdatedPassengers: (hasUpdatedPassengers: boolean) => void;
  setInTransition: (inTransition: InTransition) => void;
  shouldShowSeatmap: boolean;
  steps: BookingStepFragment[];
  triggerBookingFlowStepTransition: (values?: {
    as?: string;
    goToPreviousStep?: boolean;
    query?: ParsedUrlQuery;
    route?: string;
  }) => void;
};

const inTransitionInitalState = {
  next: false,
  previous: false,
};

const context = createContext<Step>({
  activeStepIndex: 0,
  steps: [],
  triggerBookingFlowStepTransition: () => {},
  inTransition: inTransitionInitalState,
  setInTransition: () => {},
  isLastStep: false,
  firstBookingStepIndex: 2,
  hasNavigatedFromFirstStep: false,
  setHasNavigatedFromFirstStep: () => {},
  hasClickedNextButton: false,
  setHasClickedNextButton: () => {},
  setHasUpdatedPassengers: () => {},
  hasUpdatedPassengers: false,
  shouldShowSeatmap: true,
  isFirstBookingStep: true,
});

export default context;

export const StepProvider = ({ children }: { children: React.ReactNode }) => {
  const [inTransition, setInTransition] = useState<InTransition>(
    inTransitionInitalState,
  );

  const { passengerRules } = usePartnerPassengersConfig();

  const { push, query, route } = usePartnerRouter();

  const { vendors } = useVendors({
    includeBookingData: true,
    iatas: parseBookingDeeplink(query, passengerRules)?.itineraryIatas ?? [],
  });

  const shouldShowSeatmap = shouldShowSeatmapForVendors(vendors);

  const { steps } = useBookingConfig();

  const filteredSteps =
    Object.keys(vendors).length > 0
      ? steps.filter((step) =>
          step.route.includes('seats') ? shouldShowSeatmap : true,
        )
      : steps;

  const activeStepIndex = filteredSteps.findIndex((step) => {
    if (step.route === '/') {
      return step.route === route;
    }

    return route.includes(step.route);
  });

  const firstBookingStepIndex = steps.findIndex(
    (step) => step.isFirstBookingStep,
  );

  const [hasNavigatedFromFirstStep, setHasNavigatedFromFirstStep] = useState<
    null | boolean
  >(false);

  const [hasClickedNextButton, setHasClickedNextButton] = useState(false);
  const [hasUpdatedPassengers, setHasUpdatedPassengers] = useState(false);

  useEffect(() => {
    if (
      steps.length > 0 &&
      !hasNavigatedFromFirstStep &&
      activeStepIndex > -1
    ) {
      setHasNavigatedFromFirstStep(activeStepIndex !== firstBookingStepIndex);
    }
  }, [
    activeStepIndex,
    firstBookingStepIndex,
    hasNavigatedFromFirstStep,
    steps.length,
  ]);

  const triggerBookingFlowStepTransition = async (values?: {
    goToPreviousStep?: boolean;
    query?: ParsedUrlQuery;
    route?: string;
  }) => {
    if (activeStepIndex === firstBookingStepIndex && values?.goToPreviousStep) {
      return;
    }

    setInTransition({
      next: Boolean(!values?.goToPreviousStep),
      previous: Boolean(values?.goToPreviousStep),
    });

    await push({
      pathname:
        values?.route ||
        filteredSteps[activeStepIndex + (values?.goToPreviousStep ? -1 : 1)]
          ?.route,
      query: values?.query,
    });

    setInTransition({
      next: false,
      previous: false,
    });

    if (!hasNavigatedFromFirstStep) {
      setHasNavigatedFromFirstStep(true);
    }
  };

  return (
    <context.Provider
      value={{
        activeStepIndex,
        steps: filteredSteps,
        triggerBookingFlowStepTransition,
        inTransition,
        setInTransition,
        isLastStep: activeStepIndex === filteredSteps.length - 1,
        firstBookingStepIndex,
        hasNavigatedFromFirstStep,
        setHasNavigatedFromFirstStep,
        hasClickedNextButton,
        hasUpdatedPassengers,
        setHasClickedNextButton,
        setHasUpdatedPassengers,
        shouldShowSeatmap,
        isFirstBookingStep: activeStepIndex === firstBookingStepIndex,
      }}
    >
      {children}
    </context.Provider>
  );
};

export const useStep = () => useContext(context);
